import React from "react";
import Layout from "components/layout";
import Seo from "components/seo";
const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <Layout>
        <Seo
          title="Privacy Policy"
          description="Building Inspection Report Made Easy, Best for vehicle inspection report, rental inspection checklist, house inspection checklist and inspection report template. Try it FREE!"
        />
        <div className="mt-32 mb-32">
          <div className="sir-container text-left">
            <h1 className="text-4xl font-bold mb-5">Privacy Policy</h1>
            <div className="space-y-6">
              <div>
                <h2 className="font-bold mb-2 text-2xl">Introduction</h2>
                <p>
                  Site Inspection Reporting respects your privacy and this
                  policy sets out how we collect, use, store, disclose and
                  otherwise treat your personal information as required by the
                  Australian Privacy Principles set out in the Privacy Act 1988
                  (Cth) (“Privacy Act”).
                </p>
              </div>
              <div>
                <h2 className="font-bold mb-2 text-2xl">
                  Information Collected
                </h2>
                <p>
                  siteinspectionreporting.com.au collects certain information
                  about visitors to its website, in such cases as where an email
                  is sent to Site Inspection Reporting or when one of our online
                  forms is completed. Site Inspection Reporting will use the
                  information provided for administrative and marketing purposes
                  such as fulfilling subscription requests, understanding, and
                  analysing your responses, needs and preferences, and marketing
                  and providing you information and services. The personal
                  information provided can identify Website visitors and their
                  businesses, and may include such items as name, business name,
                  email address, job title, job function, location of your
                  business.
                </p>
              </div>
              <div>
                <h2 className="font-bold mb-2 text-2xl">
                  Cookies and Other Technologies
                </h2>
                <p>
                  A cookie is a small text file that is sent to your browser
                  from a website and stored on your computer’s hard drive. As is
                  standard practice on many Websites, Site Inspection Reporting
                  uses “cookies” to differentiate one user from another and to
                  pass information from page to page during a single user’s
                  website session. They are read only by the server that placed
                  them and cannot execute any code or virus. Cookies also help
                  us understand which parts of our website are the most popular,
                  where our visitors are going and how much time they spend
                  there.
                </p>
              </div>
              <div>
                <h2 className="font-bold mb-2 text-2xl">
                  How Information Provided by You Online is Used
                </h2>
                <p>
                  We may use the information provided through our online forms
                  to facilitate engagement with Site Inspection Reporting and to
                  pre-register, respond to your request for information about
                  Site Inspection Reporting and its products, respond to
                  comments you have about our website, or services provided by
                  Site Inspection Reporting. We may also aggregate the
                  non-personal data for system administration purposes and to
                  monitor use of the site. The aggregated data measures site
                  visit information such as number of visits, number of pages
                  viewed and other site statistics, and diagnoses of server
                  requirements. This monitoring process enables us to
                  continuously improve Content. We may also retain information
                  regarding links to our website. Site Inspection Reporting may
                  also disclose personal, or business contact information
                  provided to it to comply with legal and regulatory
                  requirements or to ensure compliance with the terms and
                  conditions of this Website.
                </p>
              </div>
              <div>
                <h2 className="font-bold mb-2 text-2xl">
                  How Your Information is Protected
                </h2>
                <p>
                  Site Inspection Reporting takes physical, electronic and
                  procedural measures to prevent unauthorized intrusion to our
                  website and the personal and business contact information
                  collected.
                </p>
              </div>
              <div>
                <h2 className="font-bold mb-2 text-2xl">Contact Us</h2>
                <p>
                  Enquiries about Site Inspection Reporting privacy policy can
                  be forwarded to the Site Inspection Reporting Privacy
                  Administrator at The Waterman Centre Level 2 UL40/1341
                  Dandenong Road Chadstone VIC 3148, Australia or at{" "}
                  <a
                    className="text-sir-secondary underline"
                    href="hello@siteinspectionreporting.com.au"
                  >
                    hello@siteinspectionreporting.com.au
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
